import React, { useState } from "react";
import ClaimStep1 from "./step-1/ClaimStep1";
import ClaimStep2 from "./step-2/ClaimStep2";
import ClaimStep3 from "./step-3/ClaimStep3";

const Claim = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [rate, setRate] = useState(0);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <ClaimStep1 />;
      case 2:
        return <ClaimStep2 />;
      case 3:
        return <ClaimStep3 />;
      default:
        return null;
    }
  };

  const nextStep = (inputValue, rate) => {
    setCurrentStep((prevStep) => prevStep + 1);
    setInputValue(inputValue);
    setRate(rate); 
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <div>
      {currentStep === 1 && <ClaimStep1 nextStep={nextStep} />}
      {currentStep === 2 && <ClaimStep2 inputValue={inputValue} rate={rate} prevStep={prevStep} nextStep={nextStep} />}
      {currentStep === 3 && <ClaimStep3 prevStep={prevStep} />}
    </div>
  );
};

export default Claim;
