import React, { useEffect, useState } from "react";
import { useUser } from "../../api/userContext";
import "./boosts.css";
import { formatNumber } from "../../utils/utils";
import Modal from "../../components/modal/modal";
import {
  AUTO_CLICKER_COINS,
  MULTIPLIER_COINS,
  ROCKET_COINS,
} from "./constants";
import { boosterApply } from "../../api/apiRequests";
import Loader from "../../components/loader/Loader";

const Boosts = () => {
  const userData = useUser();
  const [isModalRocketOpen, setModalRocketOpen] = useState(false);
  const [isModalAutoclickerOpen, setModalAutoclickerOpen] = useState(false);
  const [isModalMultiplierOpen, setModalMultiplierOpen] = useState(false);
  const [isModalHowBoostsWorkOpen, setModalHowBoostsWorkOpen] = useState(false);
  const [user, setUser] = useState({});
  const [isLoadingBoosterApply, setIsLoadingBoosterApply] = useState(false);

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  const handleOpenModal = (modalId) => {
    if (modalId === "modalRocket") {
      setModalRocketOpen(true);
    } else if (modalId === "modalAutoclicker") {
      setModalAutoclickerOpen(true);
    } else if (modalId === "modalMultipliers") {
      setModalMultiplierOpen(true);
    } else if (modalId === "modalHowBoostsWork") {
      setModalHowBoostsWorkOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalRocketOpen(false);
    setModalAutoclickerOpen(false);
    setModalMultiplierOpen(false);
    setModalHowBoostsWorkOpen(false);
  };

  const handleBoosterApply = (type) => {
    setIsLoadingBoosterApply(true);
    boosterApply(type).then((response) => {
      setUser({
        ...user,
        balance_coins: response.balance_coins,
        spent_coins: response.spent_coins,
        with_robot: response.with_robot,
        multiple_clicks: response.multiple_clicks,
        limit_coins: response.limit_coins,
      });
      setIsLoadingBoosterApply(false);
    });
  };

  if (!user?.balance_coins) {
    return (
      <div>
        <Loader/>
      </div>
    );
  }

  const getLevelEnergyBar = (limit_coins) => {
    return (limit_coins - 1000) / 100;
  };
  const openInvitePage = () => {
    // Функция для открытия страницы приглашения
  };

  const vibrate = () => {
    // Функция для вибрации (если требуется)
  };

  return (
    <div className="wrapper pgT-60">
      <div>
        <Modal
          isOpen={isModalRocketOpen}
          onClose={
            isModalRocketOpen
              ? () => setModalRocketOpen(false)
              : handleCloseModal
          }
        >
          <img
            src="assets/img/bosters/rocket.png"
            alt=""
            className="modal_img"
          />
          <div className="modal_row">
            <h2 className="modal_title">Energy Bar</h2>
            <p className="modal_under-title">
              Boost ENERGY BAR by 10% coins/hour:
            </p>
          </div>
          <div className="modal_col">
            <div className="modal__total-div-boosts">
              <img
                src="assets/img/coin.png"
                alt=""
                className="modal_img-coin-boosts"
              />
              <span className="modal_balance_coins-boosts">25 000</span>
            </div>
            <p className="modal_under-title">10 boosters available</p>
          </div>
          <button
            className="modal__button-big-yellow"
            disabled={
              isLoadingBoosterApply || getLevelEnergyBar(user.limit_coins) >= 10
            }
            onClick={() => handleBoosterApply("energy")}
          >
            Get booster
          </button>
        </Modal>

        <Modal
          isOpen={isModalAutoclickerOpen}
          onClose={
            isModalAutoclickerOpen
              ? () => setModalAutoclickerOpen(false)
              : handleCloseModal
          }
        >
          <img
            src="assets/img/bosters/autocliker-icon.png"
            alt=""
            className="modal_img"
          />
          <div className="modal_row">
            <h2 className="modal_title">Autoclicker</h2>
            <p className="modal_under-title">Automatically collects coins</p>
          </div>
          <div className="modal_col">
            <div className="modal__total-div-boosts">
              <img
                src="assets/img/coin.png"
                alt=""
                className="modal_img-coin-boosts"
              />
              <span className="modal_balance_coins-boosts">100 000</span>
            </div>
            <p className="modal_under-title">per month</p>
          </div>
          <button
            className="modal__button-big-yellow"
            disabled={isLoadingBoosterApply || user.with_robot}
            onClick={() => handleBoosterApply("robot")}
          >
            Get booster
          </button>
        </Modal>

        <Modal
          isOpen={isModalMultiplierOpen}
          onClose={
            isModalMultiplierOpen
              ? () => setModalMultiplierOpen(false)
              : handleCloseModal
          }
        >
          <img
            src="assets/img/bosters/multi-icon.png"
            alt=""
            className="modal_img"
          />
          <div className="modal_row">
            <h2 className="modal_title">Multiplier</h2>
            <p className="modal_under-title">More coins per click</p>
          </div>
          <div className="modal_col">
            <div className="modal__total-div-boosts">
              <img
                src="assets/img/coin.png"
                alt=""
                className="modal_img-coin-boosts"
              />
              <span className="modal_balance_coins-boosts">5 000</span>
            </div>
            <p className="modal_under-title">per purchase</p>
          </div>
          <button
            className="modal__button-big-yellow"
            disabled={isLoadingBoosterApply || user?.multiple_clicks >= 4}
            onClick={() => handleBoosterApply("multiplier")}
          >
            Get booster
          </button>
        </Modal>

        <Modal
          modalHeight="80vh"
          isOpen={isModalHowBoostsWorkOpen}
          onClose={
            isModalHowBoostsWorkOpen
              ? () => setModalHowBoostsWorkOpen(false)
              : handleCloseModal
          }
        >
          <div className="modal-pizza-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 25 25"
              fill="none"
              style={{ marginBottom: "23px", marginTop: "10px" }}
            >
              <path
                d="M12.5505 22.8511C6.62254 22.8511 1.80054 18.0291 1.80054 12.1011C1.80054 6.17307 6.62254 1.35107 12.5505 1.35107C18.4785 1.35107 23.3005 6.17307 23.3005 12.1011C23.3005 18.0291 18.4785 22.8511 12.5505 22.8511ZM12.5505 2.85107C7.44954 2.85107 3.30054 7.00007 3.30054 12.1011C3.30054 17.2021 7.44954 21.3511 12.5505 21.3511C17.6515 21.3511 21.8005 17.2021 21.8005 12.1011C21.8005 7.00007 17.6515 2.85107 12.5505 2.85107ZM13.2615 13.4651C13.2855 13.3931 13.4216 13.1201 14.1536 12.6301C15.3336 11.8391 15.8616 10.709 15.6426 9.44702C15.4206 8.17202 14.3815 7.1251 13.1155 6.9021C12.1905 6.7381 11.2535 6.98701 10.5415 7.58301C9.8225 8.18601 9.41064 9.07096 9.41064 10.012C9.41064 10.426 9.74664 10.762 10.1606 10.762C10.5746 10.762 10.9106 10.426 10.9106 10.012C10.9106 9.51596 11.1276 9.04918 11.5056 8.73218C11.8816 8.41718 12.3615 8.28817 12.8545 8.37817C13.5005 8.49217 14.0515 9.04913 14.1655 9.70312C14.2085 9.95012 14.3396 10.7001 13.3186 11.3831C12.4916 11.9381 12.0216 12.4471 11.8406 12.9871C11.7086 13.3801 11.9205 13.805 12.3135 13.937C12.3925 13.964 12.4725 13.9761 12.5515 13.9761C12.8635 13.9761 13.1555 13.7781 13.2615 13.4651ZM13.5706 16.6011C13.5706 16.0491 13.1236 15.6011 12.5706 15.6011H12.5605C12.0085 15.6011 11.5654 16.0491 11.5654 16.6011C11.5654 17.1531 12.0186 17.6011 12.5706 17.6011C13.1226 17.6011 13.5706 17.1531 13.5706 16.6011Z"
                fill="white"
              />
            </svg>

            <p className="modal__standart-text mRb-23">
              <span className="white-text-color">Energy Bar</span> - you have a limit of 1000 CASH generated per hour. You
              can purchase Energy Bar boost 10 times, and each purchase will
              grant you additional 100 CASH per hour. Earn More!
            </p>

            <p className="modal__standart-text mRb-23">
            <span className="white-text-color">Autoclicker</span> - you can activate an Autocliker and generate tons of
              CASH per 1 month of activation! Earn 100% of possible!
            </p>

            <p className="modal__standart-text mRb-23">
            <span className="white-text-color">Multiplier</span> - you receive 1 CASH per 1 click. With each purchase of
              Multiplier you will increase your click value by 1 additional
              CASH. Earn Faster!
            </p>
          </div>
        </Modal>
      </div>

      <div className="your-balance mrB-12">
        <p className="mrB-12">Your balance</p>

        <div className="total-div-boosts">
          <img src="assets/img/coin.png" alt="" className="img-coin-boosts" />
          <span className="balance_coins-boosts">
            {formatNumber(user?.balance_coins)}
          </span>
        </div>
      </div>

      <div
        className="small-button-white-text mrB-40"
        onClick={() => handleOpenModal("modalHowBoostsWork")}
      >
        <span>How boosts work</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M12.5505 22.8511C6.62254 22.8511 1.80054 18.0291 1.80054 12.1011C1.80054 6.17307 6.62254 1.35107 12.5505 1.35107C18.4785 1.35107 23.3005 6.17307 23.3005 12.1011C23.3005 18.0291 18.4785 22.8511 12.5505 22.8511ZM12.5505 2.85107C7.44954 2.85107 3.30054 7.00007 3.30054 12.1011C3.30054 17.2021 7.44954 21.3511 12.5505 21.3511C17.6515 21.3511 21.8005 17.2021 21.8005 12.1011C21.8005 7.00007 17.6515 2.85107 12.5505 2.85107ZM13.2615 13.4651C13.2855 13.3931 13.4216 13.1201 14.1536 12.6301C15.3336 11.8391 15.8616 10.709 15.6426 9.44702C15.4206 8.17202 14.3815 7.1251 13.1155 6.9021C12.1905 6.7381 11.2535 6.98701 10.5415 7.58301C9.8225 8.18601 9.41064 9.07096 9.41064 10.012C9.41064 10.426 9.74664 10.762 10.1606 10.762C10.5746 10.762 10.9106 10.426 10.9106 10.012C10.9106 9.51596 11.1276 9.04918 11.5056 8.73218C11.8816 8.41718 12.3615 8.28817 12.8545 8.37817C13.5005 8.49217 14.0515 9.04913 14.1655 9.70312C14.2085 9.95012 14.3396 10.7001 13.3186 11.3831C12.4916 11.9381 12.0216 12.4471 11.8406 12.9871C11.7086 13.3801 11.9205 13.805 12.3135 13.937C12.3925 13.964 12.4725 13.9761 12.5515 13.9761C12.8635 13.9761 13.1555 13.7781 13.2615 13.4651ZM13.5706 16.6011C13.5706 16.0491 13.1236 15.6011 12.5706 15.6011H12.5605C12.0085 15.6011 11.5654 16.0491 11.5654 16.6011C11.5654 17.1531 12.0186 17.6011 12.5706 17.6011C13.1226 17.6011 13.5706 17.1531 13.5706 16.6011Z"
            fill="white"
          />
        </svg>
      </div>

      {/* <div className="invite-button-container">
        <div
          className="invite-button invite-button-click"
          onClick={openInvitePage}
          onTouchStart={vibrate}
        >
          <div className="row">
            <img
              src="assets/img/hand-ok.png"
              alt=""
              className="mini-butttom-icons pgR-12"
            />
            <div className="player-info-text">
              <div className="one-time-bonus">One-time bonus</div>
              <div className="two-row">
                <span className="small-text-button">+5000</span>
                <img
                  src="/assets/img/coin.png"
                  alt=""
                  width="18px"
                  className="row-coin"
                />
                <span className="small-text-button">
                  Invite 3 friends with Rank 1
                </span>
              </div>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M8.99998 20.25C9.19198 20.25 9.38401 20.1771 9.53001 20.0301L16.53 13.0301C16.823 12.7371 16.823 12.262 16.53 11.969L9.53001 4.96902C9.23701 4.67602 8.76197 4.67602 8.46897 4.96902C8.17597 5.26202 8.17597 5.73705 8.46897 6.03005L14.9389 12.5L8.46897 18.97C8.17597 19.263 8.17597 19.738 8.46897 20.031C8.61597 20.177 8.80798 20.25 8.99998 20.25Z"
              fill="white"
            />
          </svg>
        </div>

        <div
          className="claim-button simple"
          onClick={openInvitePage}
          onTouchStart={vibrate}
        >
          <div className="row mrB-12">
            <img
              src="assets/img/earn/moneyImg.png"
              alt=""
              className="mini-butttom-icons pgR-12"
            />
            <div className="player-info-text">
              <div className="one-time-bonus">Receive Cash</div>
            </div>
          </div>

          <div className="button-big-yellow">Claim USDT</div>
        </div>
      </div> */}

      <div className="left-align">
        <p className="middle-title">Boosters</p>
      </div>

      <div className="boosters-button-container gap-16">
        <div className="row-boosts gap-16">
          <div
            className={
              user.balance_coins > ROCKET_COINS &&
              getLevelEnergyBar(user.limit_coins) < 10
                ? " invite-button-click square-button"
                : "square-button"
            }
            onClick={() =>
              user.balance_coins > ROCKET_COINS &&
              getLevelEnergyBar(user.limit_coins) < 10
                ? handleOpenModal("modalRocket")
                : ""
            }
            // onTouchStart={vibrate}
          >
            <img
              src="assets/img/bosters/rocket.png"
              alt=""
              className="square-butttom-icons pgR-12"
            />
            <div className="row-boosts">
              <div className="player-info-text">
                <div className="title-white-middle">Energy Bar</div>
                {getLevelEnergyBar(user.limit_coins) < 10 ? (
                  <>
                    <div className="two-row-boosts mrB-5">
                      <span className="bonuses-left">
                        {getLevelEnergyBar(user.limit_coins)}
                      </span>
                      <span className="slash">/</span>
                      <span className="bonuses-total">10 available</span>
                    </div>
                    <div className="two-row-boosts">
                      <img
                        src={`/assets/img/${
                          user.balance_coins > ROCKET_COINS ? "coin" : "lock"
                        }.png`}
                        alt=""
                        width="18px"
                      />
                      <span
                        className={
                          user.balance_coins > ROCKET_COINS
                            ? "small-text-button"
                            : "small-text-button-gold"
                        }
                      >
                        25 000
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="two-row-boosts mrB-5">
                    <span className="bonuses-total">Max level</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={
              user.balance_coins > AUTO_CLICKER_COINS && !user.with_robot
                ? " invite-button-click square-button"
                : "square-button"
            }
            onClick={() =>
              user.balance_coins > AUTO_CLICKER_COINS && !user.with_robot
                ? handleOpenModal("modalAutoclicker")
                : ""
            }
            // onTouchStart={vibrate}
          >
            <img
              src="assets/img/bosters/autocliker-icon.png"
              alt=""
              className="square-butttom-icons pgR-12"
            />
            <div className="row-boosts">
              <div className="player-info-text">
                <div className="title-white-middle">Autoclicker</div>
                {!user.with_robot ? (
                  <>
                    <div className="two-row-boosts mrB-5">
                      {/*<span className="bonuses-left">0</span>*/}
                      {/*<span className="slash">/</span>*/}
                      {/*<span className="bonuses-total">30 days available</span>*/}

                      <span className="bonuses-total">not purchased</span>
                    </div>
                    <div className="two-row-boosts">
                      <img
                        src={`/assets/img/${
                          user.balance_coins > AUTO_CLICKER_COINS
                            ? "coin"
                            : "lock"
                        }.png`}
                        alt=""
                        width="18px"
                      />
                      <span
                        className={
                          user.balance_coins > AUTO_CLICKER_COINS
                            ? "small-text-button"
                            : "small-text-button-gold"
                        }
                      >
                        100 000
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="two-row-boosts mrB-5">
                    {/*<span className="bonuses-left">0</span>*/}
                    {/*<span className="slash">/</span>*/}
                    <span className="bonuses-total">30 days available</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            user.balance_coins > MULTIPLIER_COINS && user?.multiple_clicks < 4
              ? " invite-button-click square-button mrB-0"
              : "square-button mrB-0"
          }
          onClick={() =>
            user.balance_coins > MULTIPLIER_COINS && user?.multiple_clicks < 4
              ? handleOpenModal("modalMultipliers")
              : ""
          }
          //   onTouchStart={vibrate}
        >
          <div className="row-boosts">
            <img
              src="assets/img/bosters/multi-icon.png"
              alt=""
              className="multi-button-icons pgR-12"
            />
            <div className="player-info-text">
              <div className="one-time-bonus-multi">Multiplier</div>
              {user?.multiple_clicks < 4 ? (
                <>
                  <div className="two-row-boosts mrB-5">
                    <span className="bonuses-left">
                      {user?.multiple_clicks - 1 ?? 0}
                    </span>
                    <span className="slash">/</span>
                    <span className="bonuses-total">3 available</span>
                  </div>
                  <div className="two-row-boosts">
                    <img
                      src={`/assets/img/${
                        user.balance_coins > MULTIPLIER_COINS ? "coin" : "lock"
                      }.png`}
                      alt=""
                      width="18px"
                    />

                    <span
                      className={
                        user.balance_coins > MULTIPLIER_COINS
                          ? "small-text-button"
                          : "small-text-button-gold"
                      }
                    >
                      5 000
                    </span>
                  </div>
                </>
              ) : (
                <div className="two-row-boosts">
                  <span className="bonuses-total">Max level</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <SocialButtons /> */}

      {/* <div className="left-align">
        <p className="middle-title">Buy packs</p>
      </div>

      <div className="boosters-button-container gap-16">
        <div className="row-boosts gap-16">
          <div
            className="square-button invite-button-click"
            // onClick={openInvitePage}
            // onTouchStart={vibrate}
          >
            <img
              src="assets/img/bosters/dollars-pack/1-dollar-pack.png"
              alt=""
              className="square-butttom-icons pgR-12"
            />
            <div className="row-boosts">
              <div className="player-info-text">
                <div className="title-white-middle">$1 Pack</div>
                <div className="two-row-boosts">
                  <div className="two-row-boosts">
                    <span className="small-text-button-gold">10 000</span>
                    <img
                      src="/assets/img/coin.png"
                      alt=""
                      width="18px"
                      className="row-boosts-coin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="square-button invite-button-click"
            // onClick={openInvitePage}
            // onTouchStart={vibrate}
          >
            <img
              src="assets/img/bosters/dollars-pack/2dot5-dollars-pack.png"
              alt=""
              className="square-butttom-icons pgR-12"
            />
            <div className="row-boosts">
              <div className="player-info-text">
                <div className="title-white-middle">$2.5 Pack</div>
                <div className="two-row-boosts">
                  <div className="two-row-boosts">
                    <span className="small-text-button-gold">25 000</span>
                    <img
                      src="/assets/img/coin.png"
                      alt=""
                      width="18px"
                      className="row-boosts-coin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row-boosts gap-16">
          <div
            className="square-button invite-button-click"
            // onClick={openInvitePage}
            // onTouchStart={vibrate}
          >
            <img
              src="assets/img/bosters/dollars-pack/5-dollars-pack.png"
              alt=""
              className="square-butttom-icons pgR-12"
            />
            <div className="row-boosts">
              <div className="player-info-text">
                <div className="title-white-middle">$5 Pack</div>
                <div className="two-row-boosts">
                  <div className="two-row-boosts">
                    <span className="small-text-button-gold">50 000</span>
                    <img
                      src="/assets/img/coin.png"
                      alt=""
                      width="18px"
                      className="row-boosts-coin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="square-button invite-button-click"
            // onClick={openInvitePage}
            // onTouchStart={vibrate}
          >
            <img
              src="assets/img/bosters/dollars-pack/10-dollars-pack.png"
              alt=""
              className="square-butttom-icons pgR-12"
            />
            <div className="row-boosts">
              <div className="player-info-text">
                <div className="title-white-middle">$10 Pack</div>
                <div className="two-row-boosts">
                  <div className="two-row-boosts">
                    <span className="small-text-button-gold">100 000</span>
                    <img
                      src="/assets/img/coin.png"
                      alt=""
                      width="18px"
                      className="row-boosts-coin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Boosts;
