import React from "react";
import "../claim.css";

const ClaimStep3 = () => {
  return (
    <div className="claim__wrappeer">
      <svg
        className="top-img-step-3"
        xmlns="http://www.w3.org/2000/svg"
        width="163"
        height="129"
        viewBox="0 0 163 129"
        fill="none"
      >
        <path
          d="M45.7471 92.4585C71.0125 92.4585 91.4941 71.9768 91.4941 46.7114C91.4941 21.446 71.0125 0.964355 45.7471 0.964355C20.4817 0.964355 0 21.446 0 46.7114C0 71.9768 20.4817 92.4585 45.7471 92.4585Z"
          fill="url(#paint0_linear_260_5512)"
        />
        <path
          d="M45.7463 91.7885C70.6417 91.7885 90.8234 71.6068 90.8234 46.7114C90.8234 21.816 70.6417 1.63428 45.7463 1.63428C20.8509 1.63428 0.669189 21.816 0.669189 46.7114C0.669189 71.6068 20.8509 91.7885 45.7463 91.7885Z"
          fill="url(#paint1_linear_260_5512)"
        />
        <path
          d="M45.7468 91.3423C70.3958 91.3423 90.3777 71.3604 90.3777 46.7114C90.3777 22.0625 70.3958 2.08057 45.7468 2.08057C21.0979 2.08057 1.11597 22.0625 1.11597 46.7114C1.11597 71.3604 21.0979 91.3423 45.7468 91.3423Z"
          fill="url(#paint2_linear_260_5512)"
        />
        <path
          d="M45.7461 80.9316C64.6452 80.9316 79.9661 65.6108 79.9661 46.7117C79.9661 27.8125 64.6452 12.4917 45.7461 12.4917C26.8469 12.4917 11.5261 27.8125 11.5261 46.7117C11.5261 65.6108 26.8469 80.9316 45.7461 80.9316Z"
          fill="url(#paint3_linear_260_5512)"
        />
        <path
          d="M45.7451 79.7388C63.9854 79.7388 78.772 64.9522 78.772 46.7119C78.772 28.4717 63.9854 13.6851 45.7451 13.6851C27.5049 13.6851 12.7183 28.4717 12.7183 46.7119C12.7183 64.9522 27.5049 79.7388 45.7451 79.7388Z"
          fill="url(#paint4_linear_260_5512)"
        />
        <path
          d="M45.7457 79.0129C63.5855 79.0129 78.0475 64.5508 78.0475 46.711C78.0475 28.8712 63.5855 14.4092 45.7457 14.4092C27.9059 14.4092 13.4438 28.8712 13.4438 46.711C13.4438 64.5508 27.9059 79.0129 45.7457 79.0129Z"
          fill="url(#paint5_linear_260_5512)"
        />
        <path
          opacity="0.17"
          d="M75.6154 13.5492C63.8956 60.1268 24.0509 64.0961 4.19162 63.027C2.20581 57.9735 1.11597 52.4704 1.11597 46.7126C1.11597 22.0614 21.0968 2.08057 45.7468 2.08057C57.2326 2.08057 67.7066 6.41929 75.6166 13.5492H75.6154Z"
          fill="url(#paint6_linear_260_5512)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.3539 24.8906H38.4288H36.742L26.5903 35.0422V53.858L33.2832 49.3408V43.621C33.2832 36.798 38.8143 31.267 45.6372 31.267C50.5415 31.267 54.7783 34.1247 56.7733 38.2658L63.3158 33.8526L54.3539 24.8906Z"
          fill="#E8AC16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.3073 56.8415L27.646 61.34L36.8994 70.5934H54.5113L64.6629 60.4418V41.1357L57.9918 45.6383V51.9101C57.9918 58.733 52.4607 64.2641 45.6378 64.2641C40.5679 64.2641 36.2113 61.2101 34.3073 56.8415Z"
          fill="#E8AC16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.7402 42.7451C48.5706 42.7451 50.8645 45.0391 50.8645 47.8694C50.8645 50.6997 48.5706 52.9937 45.7402 52.9937C42.9099 52.9937 40.616 50.6997 40.616 47.8694C40.616 45.0391 42.9099 42.7451 45.7402 42.7451Z"
          fill="#E8AC16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.4884 23.5654H38.3181H36.6053L26.2974 33.8734V52.9789L33.0928 48.3924V42.5848C33.0928 35.6568 38.7091 30.0406 45.637 30.0406C50.6168 30.0406 54.9188 32.9422 56.9446 37.1469L63.5884 32.6654L54.4884 23.5654Z"
          fill="#FFE98E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.1326 56.0089L27.3691 60.5764L36.765 69.9723H54.6481L64.956 59.6644V40.061L58.1817 44.6333V51.0012C58.1817 57.9292 52.5654 63.5454 45.6374 63.5454C40.4896 63.5454 36.066 60.4446 34.1326 56.0089Z"
          fill="#FFE98E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.7418 41.6938C48.6157 41.6938 50.9449 44.0231 50.9449 46.897C50.9449 49.7709 48.6157 52.1002 45.7418 52.1002C42.8678 52.1002 40.5386 49.7709 40.5386 46.897C40.5386 44.0231 42.8678 41.6938 45.7418 41.6938Z"
          fill="#FFE98E"
        />
        <g filter="url(#filter0_d_260_5512)">
          <path
            d="M105.747 122.211C136.951 122.211 162.247 96.9155 162.247 65.7114C162.247 34.5073 136.951 9.21143 105.747 9.21143C74.543 9.21143 49.2471 34.5073 49.2471 65.7114C49.2471 96.9155 74.543 122.211 105.747 122.211Z"
            fill="url(#paint7_linear_260_5512)"
          />
          <path
            d="M105.746 121.384C136.493 121.384 161.419 96.4587 161.419 65.7116C161.419 34.9645 136.493 10.0391 105.746 10.0391C74.9989 10.0391 50.0735 34.9645 50.0735 65.7116C50.0735 96.4587 74.9989 121.384 105.746 121.384Z"
            fill="url(#paint8_linear_260_5512)"
          />
          <path
            d="M105.747 120.833C136.189 120.833 160.868 96.154 160.868 65.7113C160.868 35.2685 136.189 10.5898 105.747 10.5898C75.3039 10.5898 50.6252 35.2685 50.6252 65.7113C50.6252 96.154 75.3039 120.833 105.747 120.833Z"
            fill="url(#paint9_linear_260_5512)"
          />
          <path
            d="M105.746 107.975C129.087 107.975 148.009 89.0531 148.009 65.7117C148.009 42.3702 129.087 23.4482 105.746 23.4482C82.4044 23.4482 63.4824 42.3702 63.4824 65.7117C63.4824 89.0531 82.4044 107.975 105.746 107.975Z"
            fill="url(#paint10_linear_260_5512)"
          />
          <path
            d="M105.745 106.502C128.272 106.502 146.535 88.2394 146.535 65.7118C146.535 43.1841 128.272 24.9219 105.745 24.9219C83.2171 24.9219 64.9548 43.1841 64.9548 65.7118C64.9548 88.2394 83.2171 106.502 105.745 106.502Z"
            fill="url(#paint11_linear_260_5512)"
          />
          <path
            d="M105.745 105.605C127.778 105.605 145.64 87.7439 145.64 65.7109C145.64 43.6778 127.778 25.8164 105.745 25.8164C83.7122 25.8164 65.8508 43.6778 65.8508 65.7109C65.8508 87.7439 83.7122 105.605 105.745 105.605Z"
            fill="url(#paint12_linear_260_5512)"
          />
          <path
            opacity="0.17"
            d="M142.636 24.7542C128.161 82.28 78.951 87.1823 54.4238 85.8618C51.9713 79.6205 50.6252 72.8239 50.6252 65.7127C50.6252 35.2672 75.3026 10.5898 105.747 10.5898C119.932 10.5898 132.868 15.9484 142.637 24.7542H142.636Z"
            fill="url(#paint13_linear_260_5512)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.386 72.3401C111.085 72.3627 109.529 72.4559 106.058 72.4559C103.298 72.4559 101.338 72.3728 100.65 72.3401C89.9817 71.869 82.0188 70.005 82.0188 67.7733C82.0188 65.5416 89.9817 63.6801 100.65 63.2015V70.4836C101.348 70.534 103.345 70.6524 106.106 70.6524C109.418 70.6524 111.077 70.5139 111.376 70.4861V63.2065C122.022 63.6826 129.967 65.5466 129.967 67.7733C129.967 70 122.024 71.864 111.376 72.3375L111.386 72.3401ZM111.386 62.4534V55.937H126.243V46H85.7932V55.937H100.647V62.4509C88.5738 63.0076 79.4941 65.4081 79.4941 68.2846C79.4941 71.1612 88.5738 73.5592 100.647 74.1184V95H111.383V74.1108C123.43 73.5542 132.494 71.1562 132.494 68.2821C132.494 65.4081 123.437 63.0101 111.383 62.4509L111.386 62.4534Z"
            fill="#E8AC16"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M110.386 70.3401C110.085 70.3627 108.529 70.4559 105.058 70.4559C102.298 70.4559 100.338 70.3728 99.65 70.3401C88.9817 69.869 81.0188 68.005 81.0188 65.7733C81.0188 63.5416 88.9817 61.6801 99.65 61.2015V68.4836C100.348 68.534 102.345 68.6524 105.106 68.6524C108.418 68.6524 110.077 68.5139 110.376 68.4861V61.2065C121.022 61.6826 128.967 63.5466 128.967 65.7733C128.967 68 121.024 69.864 110.376 70.3375L110.386 70.3401ZM110.386 60.4534V53.937H125.243V44H84.7932V53.937H99.6475V60.4509C87.5738 61.0076 78.4941 63.4081 78.4941 66.2846C78.4941 69.1612 87.5738 71.5592 99.6475 72.1184V93H110.383V72.1108C122.43 71.5542 131.494 69.1562 131.494 66.2821C131.494 63.4081 122.437 61.0101 110.383 60.4509L110.386 60.4534Z"
            fill="url(#paint14_linear_260_5512)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_260_5512"
            x="38.2471"
            y="9.21143"
            width="124"
            height="119"
            filterUnits="userSpaceOnUse"
            fcolorinterpolationfilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-9" dy="4" />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_260_5512"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_260_5512"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_260_5512"
            x1="45.7471"
            y1="2.54406"
            x2="45.7471"
            y2="92.6489"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E3A010" />
            <stop offset="1" stopColor="#F4CD27" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_260_5512"
            x1="45.7463"
            y1="3.19103"
            x2="45.7463"
            y2="91.9766"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F4CD27" />
            <stop offset="1" stopColor="#E3A010" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_260_5512"
            x1="45.7468"
            y1="3.62126"
            x2="45.7468"
            y2="91.5281"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F4CD27" />
            <stop offset="1" stopColor="#E3A010" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_260_5512"
            x1="45.7461"
            y1="13.6722"
            x2="45.7461"
            y2="81.075"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E3A010" />
            <stop offset="1" stopColor="#F4CD27" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_260_5512"
            x1="45.7451"
            y1="14.8254"
            x2="45.7451"
            y2="79.8765"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C97E0E" />
            <stop offset="1" stopColor="#E3A010" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_260_5512"
            x1="45.7457"
            y1="15.5243"
            x2="45.7457"
            y2="79.1471"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F4CD27" />
            <stop offset="1" stopColor="#E3A010" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_260_5512"
            x1="30.9158"
            y1="23.9646"
            x2="53.9023"
            y2="60.5949"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_260_5512"
            x1="105.747"
            y1="11.1624"
            x2="105.747"
            y2="122.447"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E3A010" />
            <stop offset="1" stopColor="#F4CD27" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_260_5512"
            x1="105.746"
            y1="11.9617"
            x2="105.746"
            y2="121.617"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F4CD27" />
            <stop offset="1" stopColor="#E3A010" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_260_5512"
            x1="105.747"
            y1="12.4927"
            x2="105.747"
            y2="121.062"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F4CD27" />
            <stop offset="1" stopColor="#E3A010" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_260_5512"
            x1="105.746"
            y1="24.9062"
            x2="105.746"
            y2="108.152"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E3A010" />
            <stop offset="1" stopColor="#F4CD27" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_260_5512"
            x1="105.745"
            y1="26.3302"
            x2="105.745"
            y2="106.672"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C97E0E" />
            <stop offset="1" stopColor="#E3A010" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_260_5512"
            x1="105.745"
            y1="27.1936"
            x2="105.745"
            y2="105.771"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F4CD27" />
            <stop offset="1" stopColor="#E3A010" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_260_5512"
            x1="87.4295"
            y1="37.6178"
            x2="115.819"
            y2="82.858"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_260_5512"
            x1="104.994"
            y1="44"
            x2="104.994"
            y2="93"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE98E" />
            <stop offset="1" stopColor="#FFE98E" />
          </linearGradient>
        </defs>
      </svg>

      <svg
        className="top-light"
        xmlns="http://www.w3.org/2000/svg"
        width="367"
        height="88"
        viewBox="0 0 367 88"
        fill="none"
      >
        <path
          style={{ mixBlendMode: "screen" }}
          d="M183.5 88C284.844 88 367 68.3005 367 44C367 19.6995 284.844 0 183.5 0C82.1558 0 0 19.6995 0 44C0 68.3005 82.1558 88 183.5 88Z"
          fill="url(#paint0_radial_260_5440)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_260_5440"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(183.494 43.7958) rotate(-180) scale(176.688 42.6042)"
          >
            <stop stopColor="#FFF8F1" />
            <stop offset="0.06" stopColor="#DCD5CF" stopOpacity="0.86" />
            <stop offset="0.16" stopColor="#A8A49F" stopOpacity="0.66" />
            <stop offset="0.26" stopColor="#7B7874" stopOpacity="0.49" />
            <stop offset="0.37" stopColor="#555350" stopOpacity="0.34" />
            <stop offset="0.48" stopColor="#363533" stopOpacity="0.21" />
            <stop offset="0.59" stopColor="#1E1D1C" stopOpacity="0.12" />
            <stop offset="0.71" stopColor="#0D0C0C" stopOpacity="0.05" />
            <stop offset="0.84" stopColor="#030303" stopOpacity="0.01" />
            <stop offset="1" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>

      <span className="very-big-title-step-3" >Processing...</span>
      <span className="trans-text-step-3">It may take a few minutes. </span>
      <div
        className="claim__button-big-yellow-step-3"
        onClick={() => {
          window.location.href = "/earn";
        }}
      >
        Done
      </div>
    </div>
  );
};

export default ClaimStep3;
