import React from "react";
import "./mobileRedirectPage.css";

const MobileRedirectPage = () => {
  return (
    <div className="redirect-wrapper">
      <div className="text-container">
        <p className="redirect-title">Welcome!</p>
        <p className="redirect-title">
          Switch to your mobile device to use our app.
        </p>
      </div>
    </div>
  );
};

export default MobileRedirectPage;
