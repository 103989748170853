import React, { useState } from "react";
import "./earn.css";
import Modal from "../../components/modal/modal";

const EarnPage = () => {
  const [isClaimModalOpen, setClaimModalOpen] = useState(false);
  const [isHowCashboxsWorkModal, setHowCashboxsWorkModal] = useState(false);
  const [isHowCashboxsWorkModalNext, setHowCashboxsWorkModalNext] =
    useState(false);

  // const handleOpenModal = () => {
  //   setModalOpen(true);
  // };

  const handleOpenModal = (modalId) => {
    if (modalId === "claimModal") {
      setClaimModalOpen(true);
    } else if (modalId === "modalHowCashboxsWork") {
      setHowCashboxsWorkModal(true);
    } else if (modalId === "modalHowCashboxsWorkNext") {
      setHowCashboxsWorkModalNext(true);
    }
  };

  const handleCloseModal = () => {
    setClaimModalOpen(false);
    setHowCashboxsWorkModal(false);
    setHowCashboxsWorkModalNext(false);
  };

  const openInvitePage = () => {
    // Функция для открытия страницы приглашения
  };

  const vibrate = () => {
    // Функция для вибрации (если требуется)
  };

  return (
    <div className="wrapper-earn">
      <Modal
        modalHeight="80vh"
        isOpen={isHowCashboxsWorkModal}
        onClose={
          isHowCashboxsWorkModal
            ? () => setHowCashboxsWorkModal(false)
            : handleCloseModal
        }
      >
        <div className="modal-pizza-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            style={{ marginBottom: "16px" }}
          >
            <path
              d="M11.1899 5.66599C11.2289 5.71999 11.25 5.78698 11.25 5.85398V19.0479C11.25 19.2729 11.013 19.4089 10.812 19.3089C8.42701 18.1189 6.03393 18.122 3.64893 18.801C3.31893 18.891 2.99902 18.661 2.99902 18.331V5.32102C2.99902 5.12802 3.10994 4.94601 3.28394 4.86301C4.92594 4.08101 6.667 3.85993 8.479 4.07993C9.604 4.21593 10.5669 4.81099 11.1899 5.66599ZM21 5.31992V18.3299C21 18.6599 20.6801 18.89 20.3501 18.8C17.9661 18.121 15.573 18.118 13.187 19.308C12.986 19.408 12.749 19.272 12.749 19.047V5.853C12.749 5.786 12.7701 5.71901 12.8091 5.66501C13.4321 4.81001 14.396 4.21495 15.519 4.07895C17.331 3.85895 19.0731 4.07903 20.7141 4.86203C20.8891 4.94503 21 5.12692 21 5.31992ZM17.75 12C17.75 11.586 17.414 11.25 17 11.25H15C14.586 11.25 14.25 11.586 14.25 12C14.25 12.414 14.586 12.75 15 12.75H17C17.414 12.75 17.75 12.414 17.75 12ZM18.75 8.99997C18.75 8.58597 18.414 8.24997 18 8.24997H15C14.586 8.24997 14.25 8.58597 14.25 8.99997C14.25 9.41397 14.586 9.74997 15 9.74997H18C18.414 9.74997 18.75 9.41397 18.75 8.99997Z"
              fill="white"
            />
          </svg>
          <span className="mRb-23">How cashbox work</span>
          <p className="modal__standart-text mRb-23">
            By buying CASHBOX - you are allowed to swap your CASH to USDT, with
            a limited amount of your total balance depending on current
            liquidity and your balance/CASHBOX type.
          </p>
          <p className="modal__standart-text">
            The calculation of what percentage of your balance you can withdraw
            is being calculated in a moment of buy within the next formula:
          </p>
          <p className="modal__standart-text mRb-23">
            Your CASH balance*CASHBOX Price/2/Current system Liquidity/Your CASH
            balance/Current CASH in circulation*0.9
          </p>

          <div
            className="modal__button-big-yellow"
            onClick={() => {
              setHowCashboxsWorkModal(false);
              setHowCashboxsWorkModalNext(true);
            }}
          >
            Next
          </div>
        </div>
      </Modal>

      <Modal
        modalHeight="80vh"
        isOpen={isHowCashboxsWorkModalNext}
        onClose={
          isHowCashboxsWorkModalNext
            ? () => setHowCashboxsWorkModalNext(false)
            : handleCloseModal
        }
      >
        <div className="modal-pizza-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            style={{ marginBottom: "16px" }}
          >
            <path
              d="M11.1899 5.66599C11.2289 5.71999 11.25 5.78698 11.25 5.85398V19.0479C11.25 19.2729 11.013 19.4089 10.812 19.3089C8.42701 18.1189 6.03393 18.122 3.64893 18.801C3.31893 18.891 2.99902 18.661 2.99902 18.331V5.32102C2.99902 5.12802 3.10994 4.94601 3.28394 4.86301C4.92594 4.08101 6.667 3.85993 8.479 4.07993C9.604 4.21593 10.5669 4.81099 11.1899 5.66599ZM21 5.31992V18.3299C21 18.6599 20.6801 18.89 20.3501 18.8C17.9661 18.121 15.573 18.118 13.187 19.308C12.986 19.408 12.749 19.272 12.749 19.047V5.853C12.749 5.786 12.7701 5.71901 12.8091 5.66501C13.4321 4.81001 14.396 4.21495 15.519 4.07895C17.331 3.85895 19.0731 4.07903 20.7141 4.86203C20.8891 4.94503 21 5.12692 21 5.31992ZM17.75 12C17.75 11.586 17.414 11.25 17 11.25H15C14.586 11.25 14.25 11.586 14.25 12C14.25 12.414 14.586 12.75 15 12.75H17C17.414 12.75 17.75 12.414 17.75 12ZM18.75 8.99997C18.75 8.58597 18.414 8.24997 18 8.24997H15C14.586 8.24997 14.25 8.58597 14.25 8.99997C14.25 9.41397 14.586 9.74997 15 9.74997H18C18.414 9.74997 18.75 9.41397 18.75 8.99997Z"
              fill="white"
            />
          </svg>
          <span className="mRb-23">How cashbox work</span>
          <p className="modal__standart-text mRb-23">
            CASHBOX are divided by 3 Tiers. You can unlock next Tier by having
            any CASHBOX from a previous one.
          </p>
          <p className="modal__standart-text mRb-23">
            CASHBOX also generates you a passive amount of CASH. The earlier you
            got - the bigger value you will earn!
          </p>
          <p className="modal__standart-text mRb-23">
            CASHBOX can only be active for certain amount of time. Tier 1 - 1
            month, Tier 2 - 3 months, Tier 3 - 6 months.
          </p>

          <div
            className="modal__button-big-yellow"
            onClick={() => {
              setHowCashboxsWorkModalNext(false);
            }}
          >
            Got it
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isClaimModalOpen}
        onClose={
          isClaimModalOpen ? () => setClaimModalOpen(false) : handleCloseModal
        }
        modalHeight="80vh"
      >
        <div className="modal-pizza-wrapper">
          <img
            src="/assets/img/claim/pizza.png"
            alt=""
            className="pizza-img"
            width="140px"
          />
          <p className="modal-pizza-title">Remember</p>
          <p className="modal-pizza-title">the Bitcoin Pizza?</p>
          <p className="pizza-text">
            You can swap CASH to USDT right now. But do you remember the guy who
            bought a pizza for 10 000 BTC?
          </p>
          <p className="pizza-text pizza-text-mGb-30">Think Twice:)</p>

          <div
            className="modal__button-big-yellow"
            onClick={() => {
              console.log("click");
            }}
          >
            Still proceed to Swap
          </div>
        </div>
      </Modal>

      <div className="top-imgs">
        <img src="/assets/img/earn/top-img.png" alt="" className="top-img" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="394"
          height="89"
          viewBox="0 0 394 89"
          fill="none"
          className="diamond-blink1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="394"
            height="89"
            viewBox="0 0 394 89"
            fill="none"
            className="diamond-blink1"
          >
            <defs>
              <radialGradient
                id="paint0_radial_115_2230"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(194.391 44.2336) rotate(-180) scale(191.995 42.3549)"
              >
                <stop stopColor="#FFF8F1" />
                <stop offset="0.06" stopColor="#DCD5CF" stopOpacity="0.86" />
                <stop offset="0.16" stopColor="#A8A49F" stopOpacity="0.66" />
                <stop offset="0.26" stopColor="#7B7874" stopOpacity="0.49" />
                <stop offset="0.37" stopColor="#555350" stopOpacity="0.34" />
                <stop offset="0.48" stopColor="#363533" stopOpacity="0.21" />
                <stop offset="0.59" stopColor="#1E1D1C" stopOpacity="0.12" />
                <stop offset="0.71" stopColor="#0D0C0C" stopOpacity="0.05" />
                <stop offset="0.84" stopColor="#030303" stopOpacity="0.01" />
                <stop offset="1" stopOpacity="0" />
              </radialGradient>
            </defs>
            <path
              className="diamond-path"
              d="M194.398 88.1791C304.522 88.1791 393.795 68.5949 393.795 44.4366C393.795 20.2783 304.522 0.694092 194.398 0.694092C84.2733 0.694092 -5 20.2783 -5 44.4366C-5 68.5949 84.2733 88.1791 194.398 88.1791Z"
              fill="url(#paint0_radial_115_2230)"
            />
          </svg>
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="237"
          height="57"
          viewBox="0 0 237 57"
          fill="none"
          className="diamond-blink2"
        >
          <defs>
            <radialGradient
              id="paint0_radial_115_2231"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(128.715 33.9472) rotate(10.9725) scale(115.724 16.5305)"
            >
              <stop stopColor="#FFF8F1" />
              <stop offset="0.06" stopColor="#DCD5CF" stopOpacity="0.86" />
              <stop offset="0.16" stopColor="#A8A49F" stopOpacity="0.66" />
              <stop offset="0.26" stopColor="#7B7874" stopOpacity="0.49" />
              <stop offset="0.37" stopColor="#555350" stopOpacity="0.34" />
              <stop offset="0.48" stopColor="#363533" stopOpacity="0.21" />
              <stop offset="0.59" stopColor="#1E1D1C" stopOpacity="0.12" />
              <stop offset="0.71" stopColor="#0D0C0C" stopOpacity="0.05" />
              <stop offset="0.84" stopColor="#030303" stopOpacity="0.01" />
              <stop offset="1" stopOpacity="0" />
            </radialGradient>
          </defs>
          <path
            className="diamond-path"
            d="M236.115 51.2579C236.953 41.9161 184.93 24.1253 119.92 11.5209C54.9091 -1.08343 1.52882 -3.72828 0.691443 5.61351C-0.145937 14.9553 51.8767 32.7462 116.887 45.3505C181.898 57.9548 235.278 60.5997 236.115 51.2579Z"
            fill="url(#paint0_radial_115_2231)"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="88"
          height="111"
          viewBox="0 0 88 111"
          fill="none"
          className="diamond-blink3"
        >
          <defs>
            <radialGradient
              id="paint0_radial_115_2232"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(46.3393 58.8426) rotate(-51.2981) scale(65.9108 13.6904)"
            >
              <stop stopColor="#FFF8F1" />
              <stop offset="0.06" stopColor="#DCD5CF" stopOpacity="0.86" />
              <stop offset="0.16" stopColor="#A8A49F" stopOpacity="0.66" />
              <stop offset="0.26" stopColor="#7B7874" stopOpacity="0.49" />
              <stop offset="0.37" stopColor="#555350" stopOpacity="0.34" />
              <stop offset="0.48" stopColor="#363533" stopOpacity="0.21" />
              <stop offset="0.59" stopColor="#1E1D1C" stopOpacity="0.12" />
              <stop offset="0.71" stopColor="#0D0C0C" stopOpacity="0.05" />
              <stop offset="0.84" stopColor="#030303" stopOpacity="0.01" />
              <stop offset="1" stopOpacity="0" />
            </radialGradient>
          </defs>
          <path
            className="diamond-path"
            d="M51.9002 68.4362C75.5382 38.9332 91.3398 9.19229 87.1942 2.00808C83.0485 -5.17612 60.5255 12.9169 36.8875 42.4199C13.2496 71.9229 -2.55208 101.664 1.59356 108.848C5.7392 116.032 28.2623 97.9392 51.9002 68.4362Z"
            fill="url(#paint0_radial_115_2232)"
          />
        </svg>
        <p className="big-white-title absolute">Earn more coins</p>
      </div>

      <div className="small-button-white-text mrB-24">
        <span onClick={() => handleOpenModal("modalHowCashboxsWork")}>
          How cashbox work
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M11.1899 5.66599C11.2289 5.71999 11.25 5.78698 11.25 5.85398V19.0479C11.25 19.2729 11.013 19.4089 10.812 19.3089C8.42701 18.1189 6.03393 18.122 3.64893 18.801C3.31893 18.891 2.99902 18.661 2.99902 18.331V5.32102C2.99902 5.12802 3.10994 4.94601 3.28394 4.86301C4.92594 4.08101 6.667 3.85993 8.479 4.07993C9.604 4.21593 10.5669 4.81099 11.1899 5.66599ZM21 5.31992V18.3299C21 18.6599 20.6801 18.89 20.3501 18.8C17.9661 18.121 15.573 18.118 13.187 19.308C12.986 19.408 12.749 19.272 12.749 19.047V5.853C12.749 5.786 12.7701 5.71901 12.8091 5.66501C13.4321 4.81001 14.396 4.21495 15.519 4.07895C17.331 3.85895 19.0731 4.07903 20.7141 4.86203C20.8891 4.94503 21 5.12692 21 5.31992ZM17.75 12C17.75 11.586 17.414 11.25 17 11.25H15C14.586 11.25 14.25 11.586 14.25 12C14.25 12.414 14.586 12.75 15 12.75H17C17.414 12.75 17.75 12.414 17.75 12ZM18.75 8.99997C18.75 8.58597 18.414 8.24997 18 8.24997H15C14.586 8.24997 14.25 8.58597 14.25 8.99997C14.25 9.41397 14.586 9.74997 15 9.74997H18C18.414 9.74997 18.75 9.41397 18.75 8.99997Z"
            fill="white"
          />
        </svg>
      </div>

      <div className="invite-button-container">
        <div
          className="invite-button invite-button-click"
          onClick={openInvitePage}
          onTouchStart={vibrate}
        >
          <div className="row">
            <img
              src="assets/img/hand-ok.png"
              alt=""
              className="mini-butttom-icons pgR-12"
            />
            <div className="player-info-text">
              <div className="one-time-bonus">One-time bonus</div>
              <div className="two-row">
                <span className="small-text-button">+3000</span>
                <img
                  src="/assets/img/coin.png"
                  alt=""
                  width="18px"
                  className="row-coin"
                />
                <span className="small-text-button">
                  Invite 3 friends with Rank 1
                </span>
              </div>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M8.99998 20.25C9.19198 20.25 9.38401 20.1771 9.53001 20.0301L16.53 13.0301C16.823 12.7371 16.823 12.262 16.53 11.969L9.53001 4.96902C9.23701 4.67602 8.76197 4.67602 8.46897 4.96902C8.17597 5.26202 8.17597 5.73705 8.46897 6.03005L14.9389 12.5L8.46897 18.97C8.17597 19.263 8.17597 19.738 8.46897 20.031C8.61597 20.177 8.80798 20.25 8.99998 20.25Z"
              fill="white"
            />
          </svg>
        </div>

        <div
          className="claim-button simple"
          onClick={openInvitePage}
          onTouchStart={vibrate}
        >
          <div className="row mrB-12">
            <img
              src="assets/img/earn/moneyImg.png"
              alt=""
              className="mini-butttom-icons pgR-12"
            />
            <div className="player-info-text">
              <div className="one-time-bonus">Receive Cash</div>
            </div>
          </div>

          <div
            className="button-big-yellow"
            onClick={() => handleOpenModal("claimModal")}
          >
            Claim USDT
          </div>
        </div>
      </div>

      <div className="left-align">
        <p className="middle-title">Social</p>
      </div>

      <div className="invite-button-container mrB-140">
        <div
          className="invite-button mrB-16 invite-button-click"
          onClick={openInvitePage}
          onTouchStart={vibrate}
        >
          <div className="row">
            <img
              src="assets/img/earn/social-icon/insta.png"
              alt=""
              className="mini-butttom-icons mrR-6"
            ></img>
            <div className="column-button">
              <div className="one-time-bonus">Follow Instagram</div>
              <div className="two-row">
                <span className="small-text-button">+500</span>
                <img
                  src="/assets/img/coin.png"
                  alt=""
                  width="18px"
                  className="row-coin"
                ></img>
              </div>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M8.99998 20.25C9.19198 20.25 9.38401 20.1771 9.53001 20.0301L16.53 13.0301C16.823 12.7371 16.823 12.262 16.53 11.969L9.53001 4.96902C9.23701 4.67602 8.76197 4.67602 8.46897 4.96902C8.17597 5.26202 8.17597 5.73705 8.46897 6.03005L14.9389 12.5L8.46897 18.97C8.17597 19.263 8.17597 19.738 8.46897 20.031C8.61597 20.177 8.80798 20.25 8.99998 20.25Z"
              fill="white"
            />
          </svg>
        </div>

        <div
          className="invite-button invite-button-click"
          onClick={openInvitePage}
          onTouchStart={vibrate}
        >
          <div className="row">
            <img
              src="assets/img/earn/social-icon/X.png"
              alt=""
              className="mini-butttom-icons mrR-6"
            ></img>
            <div className="column-button">
              <div className="one-time-bonus">Follow X</div>
              <div className="two-row">
                <span className="small-text-button">+500</span>
                <img
                  src="/assets/img/coin.png"
                  alt=""
                  width="18px"
                  className="row-coin"
                ></img>
              </div>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M8.99998 20.25C9.19198 20.25 9.38401 20.1771 9.53001 20.0301L16.53 13.0301C16.823 12.7371 16.823 12.262 16.53 11.969L9.53001 4.96902C9.23701 4.67602 8.76197 4.67602 8.46897 4.96902C8.17597 5.26202 8.17597 5.73705 8.46897 6.03005L14.9389 12.5L8.46897 18.97C8.17597 19.263 8.17597 19.738 8.46897 20.031C8.61597 20.177 8.80798 20.25 8.99998 20.25Z"
              fill="white"
            />
          </svg>
        </div>

        <div
          className="invite-button invite-button-click"
          onClick={openInvitePage}
          onTouchStart={vibrate}
        >
          <div className="row">
            <img
              src="assets/img/earn/social-icon/tt.png"
              alt=""
              className="mini-butttom-icons mrR-6"
            ></img>
            <div className="column-button">
              <div className="one-time-bonus">Follow TikTok</div>
              <div className="two-row">
                <span className="small-text-button">+500</span>
                <img
                  src="/assets/img/coin.png"
                  alt=""
                  width="18px"
                  className="row-coin"
                ></img>
              </div>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M8.99998 20.25C9.19198 20.25 9.38401 20.1771 9.53001 20.0301L16.53 13.0301C16.823 12.7371 16.823 12.262 16.53 11.969L9.53001 4.96902C9.23701 4.67602 8.76197 4.67602 8.46897 4.96902C8.17597 5.26202 8.17597 5.73705 8.46897 6.03005L14.9389 12.5L8.46897 18.97C8.17597 19.263 8.17597 19.738 8.46897 20.031C8.61597 20.177 8.80798 20.25 8.99998 20.25Z"
              fill="white"
            />
          </svg>
        </div>

        <div
          className="invite-button invite-button-click"
          onClick={openInvitePage}
          onTouchStart={vibrate}
        >
          <div className="row">
            <img
              src="assets/img/earn/social-icon/telegram.png"
              alt=""
              className="mini-butttom-icons mrR-6"
            ></img>
            <div className="column-button">
              <div className="one-time-bonus">Subscribe to Telegram</div>
              <div className="two-row">
                <span className="small-text-button">+500</span>
                <img
                  src="/assets/img/coin.png"
                  alt=""
                  width="18px"
                  className="row-coin"
                ></img>
              </div>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M8.99998 20.25C9.19198 20.25 9.38401 20.1771 9.53001 20.0301L16.53 13.0301C16.823 12.7371 16.823 12.262 16.53 11.969L9.53001 4.96902C9.23701 4.67602 8.76197 4.67602 8.46897 4.96902C8.17597 5.26202 8.17597 5.73705 8.46897 6.03005L14.9389 12.5L8.46897 18.97C8.17597 19.263 8.17597 19.738 8.46897 20.031C8.61597 20.177 8.80798 20.25 8.99998 20.25Z"
              fill="white"
            />
          </svg>
        </div>

        <div
          className="invite-button invite-button-click"
          onClick={openInvitePage}
          onTouchStart={vibrate}
        >
          <div className="row">
            <img
              src="assets/img/earn/social-icon/reTweet.png"
              alt=""
              className="mini-butttom-icons mrR-6"
            ></img>
            <div className="column-button">
              <div className="one-time-bonus">reTweet</div>
              <div className="two-row">
                <span className="small-text-button">+500</span>
                <img
                  src="/assets/img/coin.png"
                  alt=""
                  width="18px"
                  className="row-coin"
                ></img>
              </div>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M8.99998 20.25C9.19198 20.25 9.38401 20.1771 9.53001 20.0301L16.53 13.0301C16.823 12.7371 16.823 12.262 16.53 11.969L9.53001 4.96902C9.23701 4.67602 8.76197 4.67602 8.46897 4.96902C8.17597 5.26202 8.17597 5.73705 8.46897 6.03005L14.9389 12.5L8.46897 18.97C8.17597 19.263 8.17597 19.738 8.46897 20.031C8.61597 20.177 8.80798 20.25 8.99998 20.25Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default EarnPage;
