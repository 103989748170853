import React, { useEffect, useState } from "react";
import { useUser } from "../../api/userContext";
import {getKings} from "../../api/apiRequests";
import PlayerListKing from "../../components/king/PlayerListKing";
import "./king.css";
import {handleRefLink} from "../../helpers/telegram";
import Loader from "../../components/loader/Loader";

const King = () => {
  const [kingPlayers, setKingPlayers] = useState(null);

  const user = useUser();

  useEffect(() => {
      getKings( )
      .then((response) => {
          setKingPlayers(response);
      });
  }, []);

  if (!kingPlayers) {
    return (
      <div>
        <Loader/>
      </div>
    );
  }

  return (
    <div className="wrapper-king">
      <div className="top-imgs-king">
        <img
          src="/assets/img/king/top-img.png"
          alt=""
          className="confetti-king"
        />
      </div>
      <div className="significant-bg">
        <p className="big-white-title mB-10">Kings of the Party</p>
        <p className="semi-significant-text-king">
          Invite more friends to come here
        </p>

        <div className="button-big-yellow" onClick={() => handleRefLink(user.user_id)}>Invite a friend</div>
      </div>

      {!kingPlayers || Object.keys(kingPlayers).length === 0 ? (
        <div className="main-table-league empty-league">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <g opacity="0.4">
              <path
                d="M55.864 18.5946C54.1093 17.3306 51.8161 17.288 50.0188 18.488L43.8427 22.6053C43.64 22.7413 43.44 22.7146 43.3414 22.6853C43.24 22.6586 43.0587 22.5787 42.9547 22.36L36.6321 8.94401C35.7761 7.12801 34.0001 6 31.9921 6C31.9921 6 31.992 6 31.9893 6C29.9813 6 28.2053 7.1307 27.3493 8.9467L21.0455 22.3573C20.9415 22.5787 20.7573 22.656 20.656 22.6853C20.5573 22.7146 20.36 22.7413 20.1547 22.6053L13.9787 18.488C12.1814 17.2907 9.88537 17.3333 8.13337 18.5946C6.38137 19.8586 5.61611 22.0213 6.17878 24.08L11.0294 42.832C11.712 45.48 14.1015 47.3306 16.8375 47.3306H47.1575C49.8935 47.3306 52.2827 45.48 52.9654 42.832L57.808 24.104C58.3813 22.024 57.616 19.8586 55.864 18.5946ZM53.9467 23.08L49.0961 41.8346C48.8694 42.7173 48.0721 43.3333 47.1601 43.3333H16.8401C15.9281 43.3333 15.1307 42.7173 14.904 41.8346L10.0481 23.056C9.86141 22.3733 10.2854 21.9814 10.4774 21.8427C10.5948 21.7574 10.8374 21.6187 11.1441 21.6187C11.3308 21.6187 11.5414 21.672 11.7654 21.8187L17.9415 25.936C19.0588 26.6827 20.4481 26.9013 21.7335 26.5413C23.0268 26.1813 24.096 25.2774 24.6693 24.0614L30.9734 10.6506C31.248 10.0666 31.7787 10.0027 31.9947 10.0027C32.2107 10.0027 32.7413 10.0666 33.016 10.6506L39.3388 24.0666C39.9094 25.28 40.9787 26.1813 42.272 26.5413C43.5654 26.8987 44.9467 26.68 46.064 25.936L52.2401 21.8187C52.8295 21.4267 53.3361 21.704 53.5281 21.8427C53.7147 21.9787 54.1387 22.3733 53.9467 23.08ZM51.3334 53.3333C51.3334 54.4373 50.4374 55.3333 49.3334 55.3333H14.6667C13.5627 55.3333 12.6667 54.4373 12.6667 53.3333C12.6667 52.2293 13.5627 51.3333 14.6667 51.3333H49.3334C50.4374 51.3333 51.3334 52.2293 51.3334 53.3333Z"
                fill="white"
              />
            </g>
          </svg>
          Leaderboard is under construction
        </div>
      ) : (
        <div className="main-table-league">
          <PlayerListKing kingPlayers={kingPlayers}></PlayerListKing>
        </div>
      )}
    </div>
  );
};

export default King;
