import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <span className="loader-title">Loading...</span>

      <div>
        <img src={`assets/img/league/diamonds/2.png`} alt="" style={{height: '136px'}}/>
      </div>
    </div>
  );
};

export default Loader;
