import React, { useEffect, useState } from "react";
import { useUser } from "../../api/userContext";
import { getLeaguePlayers } from "../../api/apiRequests";
import {
  Diamond1,
  Diamond2,
  Diamond3,
} from "../../components/league/Blinks/Blinks";
import PlayerList from "../../components/league/PlayerInfo/PlayerInfo";
import { formatNumber, getTitleLeague } from "../../utils/utils";
import Modal from "../../components/modal/modal";
import "./league.css";
import Loader from "../../components/loader/Loader";

const League = () => {
  const [leaguePlayers, setLeaguePlayers] = useState(null);
  const [currentLeagueNumber, setCurrentLeagueNumber] = useState(null);
  const [touchStartX, setTouchStartX] = useState(null);
  const [userLeague, setUserLeague] = useState(null);
  const [progressBarWidth, setProgressBarWidth] = useState(0); //ширина прогрес бара на сладере

  const userData = useUser();

  const user = useUser();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Определение пороговых значений для каждой лиги
  const leagueThresholds = {
    1: 5000,
    2: 25000,
    3: 100000,
    4: 250000,
    5: 1000000,
    6: 2500000,
    7: 5000000,
    8: 10000000,
    9: 25000000,
    10: 50000000,
  };

  useEffect(() => {
    if (userData?.league_id) {
      setUserLeague(userData.league_id);
      setCurrentLeagueNumber(userData.league_id);
    }
  }, [userData]);

  useEffect(() => {
    if (userData.token && currentLeagueNumber > 0) {
      getLeaguePlayers(currentLeagueNumber).then((response) => {
        setLeaguePlayers(response);
      });
    }
  }, [userData.token, currentLeagueNumber]);

  useEffect(() => {
    if (userData.total_coins && currentLeagueNumber) {
      const leagueThreshold = leagueThresholds[currentLeagueNumber];
      const progressBarWidth = (userData.total_coins / leagueThreshold) * 100;
      setProgressBarWidth(progressBarWidth);
      console.log("progressBarWidth: ", progressBarWidth);
    }
  }, [userData.total_coins, currentLeagueNumber]);

  const openTotalPlayersPage = () => {
    window.location.href = "total-players";
  };

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const clickSliderLeft = () => {
    if (currentLeagueNumber > 1) {
      setCurrentLeagueNumber((prevNumber) => prevNumber - 1);
    } else {
      //   setCurrentLeagueNumber(10);
    }
  };

  const clickSliderRight = () => {
    if (currentLeagueNumber < 10) {
      setCurrentLeagueNumber((prevNumber) => prevNumber + 1);
    } else {
      //   setCurrentLeagueNumber(1);
    }
  };

  const vibrate = () => {
    if ("vibrate" in navigator) {
      navigator.vibrate(100);
    }
  };

  if (!leaguePlayers) {
    return (
      <div>
        <Loader/>
      </div>
    );
  }

  const handleTouchMove = (event) => {
    if (touchStartX) {
      const touchEndX = event.touches[0].clientX;
      const deltaX = touchEndX - touchStartX;

      if (deltaX > 5) {
        // Свайп вправо
        if (currentLeagueNumber > 1) {
          setCurrentLeagueNumber((prevNumber) => prevNumber - 1);
        } else {
          setCurrentLeagueNumber(5);
        }
      } else if (deltaX < -5) {
        // Свайп влево
        if (currentLeagueNumber < 5) {
          setCurrentLeagueNumber((prevNumber) => prevNumber + 1);
        } else {
          setCurrentLeagueNumber(1);
        }
      }

      setTouchStartX(null);
    }
  };

  return (
    <div className={`main league-background-${currentLeagueNumber}`}>
      <Modal
        isOpen={isModalOpen}
        onClose={isModalOpen ? () => setModalOpen(false) : handleCloseModal}
        modalHeight="70vh"
      >
        <div className="modal-pizza-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 22 22"
            fill="none"
            style={{ marginBottom: "23px", marginTop: "10px", zIndex: "999" }}
          >
            <path
              d="M11 21.75C5.072 21.75 0.25 16.928 0.25 11C0.25 5.072 5.072 0.25 11 0.25C16.928 0.25 21.75 5.072 21.75 11C21.75 16.928 16.928 21.75 11 21.75ZM11 1.75C5.899 1.75 1.75 5.899 1.75 11C1.75 16.101 5.899 20.25 11 20.25C16.101 20.25 20.25 16.101 20.25 11C20.25 5.899 16.101 1.75 11 1.75ZM11.75 15.5V10.929C11.75 10.515 11.414 10.179 11 10.179C10.586 10.179 10.25 10.515 10.25 10.929V15.5C10.25 15.914 10.586 16.25 11 16.25C11.414 16.25 11.75 15.914 11.75 15.5ZM12.02 7.5C12.02 6.948 11.573 6.5 11.02 6.5H11.01C10.458 6.5 10.0149 6.948 10.0149 7.5C10.0149 8.052 10.468 8.5 11.02 8.5C11.572 8.5 12.02 8.052 12.02 7.5Z"
              fill="white"
            />
          </svg>
          <p className="modal__standart-text mRb-23">
            Leagues are depending on how much CASH do you have on your balance
            right now!
          </p>
          <p className="modal__standart-text mRb-23">
            The higher your League is - the bigger amount of CASH you can swap
            to USDT weekly. Starting from Aquamarine League your referral is
            increased from 30% up to 40%
          </p>
        </div>
      </Modal>

      <div className={`wrapper-league `}>
        <div className="header-league">
          <div
            className="button-league"
            onClick={openTotalPlayersPage}
            onTouchStart={vibrate}
          >
            <span className="button-text-league">Total cash minted:</span>
            <div className="total-container-league">
              <span className="total-score-league">
                {userData.stat?.total_coins &&
                  formatNumber(userData.stat.total_coins)}
              </span>

              <img
                src="./assets/img/coin.png"
                alt=""
                className="img-coin-league"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M8.99998 20.25C9.19198 20.25 9.38401 20.1771 9.53001 20.0301L16.53 13.0301C16.823 12.7371 16.823 12.262 16.53 11.969L9.53001 4.96902C9.23701 4.67602 8.76197 4.67602 8.46897 4.96902C8.17597 5.26202 8.17597 5.73705 8.46897 6.03005L14.9389 12.5L8.46897 18.97C8.17597 19.263 8.17597 19.738 8.46897 20.031C8.61597 20.177 8.80798 20.25 8.99998 20.25Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <img
            src={`assets/img/league/diamonds/${currentLeagueNumber}.png`}
            alt=""
            className="diamonds-league"
          />
          <Diamond1></Diamond1>
          <Diamond2></Diamond2>
          <Diamond3></Diamond3>

          <div
            className="view-container-league"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            <div className="text-container-league">
              <p className="text-league-league">
                {getTitleLeague(currentLeagueNumber)}
              </p>

              <p className="text-league-league">League</p>

              <div className="text-from-league ">
                {userLeague === currentLeagueNumber && (
                  <span className="white">
                    {userData.total_coins}
                    <span>/</span>
                  </span>
                )}
                <span className="mRt-8">
                  {(() => {
                    if (userLeague === currentLeagueNumber) {
                      // Если пользователь находится в указанной лиге, отображаем стандартный текст
                      switch (currentLeagueNumber) {
                        case 1:
                          return "5k";
                        case 2:
                          return "25k";
                        case 3:
                          return "100k";
                        case 4:
                          return "250k";
                        case 5:
                          return "1M";
                        case 6:
                          return "2.5M";
                        case 7:
                          return "5M";
                        case 8:
                          return "10M";
                        case 9:
                          return "25M";
                        case 10:
                          return "50M";
                        default:
                          return "";
                      }
                    } else {
                      // Если пользователь не находится в указанной лиге, отображаем текст для каждой лиги
                      switch (currentLeagueNumber) {
                        case 1:
                          return "From 0 to 5K";
                        case 2:
                          return "From 5K to 25K";
                        case 3:
                          return "From 25K to 100K";
                        case 4:
                          return "From 100K to 250K";
                        case 5:
                          return "From 250K to 1M";
                        case 6:
                          return "From 1M to 2.5M";
                        case 7:
                          return "From 2.5M to 5M";
                        case 8:
                          return "From 5M to 10M";
                        case 9:
                          return "From 10M to 25M";
                        case 10:
                          return "From 25M to 50M";
                        default:
                          return "";
                      }
                    }
                  })()}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  onClick={handleOpenModal}
                >
                  <path
                    d="M11 21.75C5.072 21.75 0.25 16.928 0.25 11C0.25 5.072 5.072 0.25 11 0.25C16.928 0.25 21.75 5.072 21.75 11C21.75 16.928 16.928 21.75 11 21.75ZM11 1.75C5.899 1.75 1.75 5.899 1.75 11C1.75 16.101 5.899 20.25 11 20.25C16.101 20.25 20.25 16.101 20.25 11C20.25 5.899 16.101 1.75 11 1.75ZM11.75 15.5V10.929C11.75 10.515 11.414 10.179 11 10.179C10.586 10.179 10.25 10.515 10.25 10.929V15.5C10.25 15.914 10.586 16.25 11 16.25C11.414 16.25 11.75 15.914 11.75 15.5ZM12.02 7.5C12.02 6.948 11.573 6.5 11.02 6.5H11.01C10.458 6.5 10.0149 6.948 10.0149 7.5C10.0149 8.052 10.468 8.5 11.02 8.5C11.572 8.5 12.02 8.052 12.02 7.5Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>

            {/* <div>
            <div className="progress-bar-container-league">
              <div className="progress-bar-full-league">
                <div
                  className="progress-bar-league"
                  style={{ width: `${userData.total_coins / 50}%` }}
                ></div>{" "}
              </div>
            </div>
            <div className="progress-bar-section-league">
              <div className="progress-empty-league"></div>
            </div>
          </div> */}

            {currentLeagueNumber === userLeague && (
              <div>
                <div className="progress-bar-container-league">
                  <div className="progress-bar-full-league">
                    <div
                      className="progress-bar-league"
                      style={{ width: `${progressBarWidth}%` }}
                    ></div>
                  </div>
                </div>
                <div className="progress-bar-section-league">
                  <div className="progress-empty-league"></div>
                </div>
              </div>
            )}

            {currentLeagueNumber !== 1 && (
              <div
                className="slider-button-league left"
                onClick={clickSliderLeft}
                onTouchStart={vibrate}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15 19.75C14.808 19.75 14.616 19.6771 14.47 19.5301L7.46999 12.5301C7.17699 12.2371 7.17699 11.762 7.46999 11.469L14.47 4.46902C14.763 4.17602 15.238 4.17602 15.531 4.46902C15.824 4.76202 15.824 5.23705 15.531 5.53005L9.06106 12L15.531 18.47C15.824 18.763 15.824 19.238 15.531 19.531C15.384 19.677 15.192 19.75 15 19.75Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}

            {currentLeagueNumber !== 10 && (
              <div
                className="slider-button-league right"
                onClick={clickSliderRight}
                onTouchStart={vibrate}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M8.99998 19.75C9.19198 19.75 9.38401 19.6771 9.53001 19.5301L16.53 12.5301C16.823 12.2371 16.823 11.762 16.53 11.469L9.53001 4.46902C9.23701 4.17602 8.76197 4.17602 8.46897 4.46902C8.17597 4.76202 8.17597 5.23705 8.46897 5.53005L14.9389 12L8.46897 18.47C8.17597 18.763 8.17597 19.238 8.46897 19.531C8.61597 19.677 8.80798 19.75 8.99998 19.75Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>

        {/* <NavigationPoints
        currentLeagueNumber={currentLeagueNumber}
      ></NavigationPoints> */}

        {!leaguePlayers || Object.keys(leaguePlayers).length === 0 ? (
          <div className="main-table-league empty-league">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
            >
              <g opacity="0.4">
                <path
                  d="M55.864 18.5946C54.1093 17.3306 51.8161 17.288 50.0188 18.488L43.8427 22.6053C43.64 22.7413 43.44 22.7146 43.3414 22.6853C43.24 22.6586 43.0587 22.5787 42.9547 22.36L36.6321 8.94401C35.7761 7.12801 34.0001 6 31.9921 6C31.9921 6 31.992 6 31.9893 6C29.9813 6 28.2053 7.1307 27.3493 8.9467L21.0455 22.3573C20.9415 22.5787 20.7573 22.656 20.656 22.6853C20.5573 22.7146 20.36 22.7413 20.1547 22.6053L13.9787 18.488C12.1814 17.2907 9.88537 17.3333 8.13337 18.5946C6.38137 19.8586 5.61611 22.0213 6.17878 24.08L11.0294 42.832C11.712 45.48 14.1015 47.3306 16.8375 47.3306H47.1575C49.8935 47.3306 52.2827 45.48 52.9654 42.832L57.808 24.104C58.3813 22.024 57.616 19.8586 55.864 18.5946ZM53.9467 23.08L49.0961 41.8346C48.8694 42.7173 48.0721 43.3333 47.1601 43.3333H16.8401C15.9281 43.3333 15.1307 42.7173 14.904 41.8346L10.0481 23.056C9.86141 22.3733 10.2854 21.9814 10.4774 21.8427C10.5948 21.7574 10.8374 21.6187 11.1441 21.6187C11.3308 21.6187 11.5414 21.672 11.7654 21.8187L17.9415 25.936C19.0588 26.6827 20.4481 26.9013 21.7335 26.5413C23.0268 26.1813 24.096 25.2774 24.6693 24.0614L30.9734 10.6506C31.248 10.0666 31.7787 10.0027 31.9947 10.0027C32.2107 10.0027 32.7413 10.0666 33.016 10.6506L39.3388 24.0666C39.9094 25.28 40.9787 26.1813 42.272 26.5413C43.5654 26.8987 44.9467 26.68 46.064 25.936L52.2401 21.8187C52.8295 21.4267 53.3361 21.704 53.5281 21.8427C53.7147 21.9787 54.1387 22.3733 53.9467 23.08ZM51.3334 53.3333C51.3334 54.4373 50.4374 55.3333 49.3334 55.3333H14.6667C13.5627 55.3333 12.6667 54.4373 12.6667 53.3333C12.6667 52.2293 13.5627 51.3333 14.6667 51.3333H49.3334C50.4374 51.3333 51.3334 52.2293 51.3334 53.3333Z"
                  fill="white"
                />
              </g>
            </svg>
            Leaderboard is under construction
          </div>
        ) : (
          <div className="main-table-league">
            <PlayerList leaguePlayers={leaguePlayers}></PlayerList>
          </div>
        )}
      </div>
    </div>
  );
};

export default League;
