import React, { useState, useEffect } from "react";
import "../claim.css";
import { useUser } from "../../../api/userContext";
import { formatNumber } from "../../../utils/utils";
import { useHistory, Link, Redirect } from "react-router-dom";
import Loader from "../../../components/loader/Loader";

const ClaimStep1 = ({ nextStep }) => {
  const userData = useUser();
  const history = useHistory();

  const [inputValue, setInputValue] = useState("0");
  const [rate, setRate] = useState(0);

  useEffect(() => {
    setRate(0.0034);
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Backspace") {
      return;
    }
    const allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const formattedInputValue = formatNumber(inputValue);

  const balance = userData ? parseFloat(userData.total_coins) : 0;
  const inputValueNumeric = parseFloat(inputValue.replace(/\s/g, ""));

  const isNotEnoughBalance = inputValueNumeric > balance;

  const handleNext = () => {
    nextStep(inputValue, rate);
  };

  if (!userData) {
    return (
      <div>
        <Loader/>
      </div>
    );
  }

  return (
    <div className="claim__wrappeer">
      <span className="claim__title">Swap</span>

      <div className="claim__form-bg">
        <div className="form__first-row">
          <div className="form__column">
            <span className="form__medium-text">You pay</span>
            <input
              type="text"
              value={formattedInputValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              inputMode="numeric"
              maxLength={13}
              onClick={() => setInputValue("")}
            />

            {isNotEnoughBalance && (
              <div className="form__warning">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  style={{ marginRight: "4px" }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.00002 12.8332C3.77836 12.8332 1.16669 10.2215 1.16669 6.99984C1.16669 3.77818 3.77836 1.1665 7.00002 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00002 12.8332ZM6.41669 4.08317V7.58317H7.58335V4.08317H6.41669ZM7.00002 9.9165C6.59731 9.9165 6.27085 9.59004 6.27085 9.18734C6.27085 8.78463 6.59731 8.45817 7.00002 8.45817C7.40273 8.45817 7.72919 8.78463 7.72919 9.18734C7.72919 9.59004 7.40273 9.9165 7.00002 9.9165Z"
                    fill="#F04E09"
                  />
                </svg>
                <span className="form-small-red-text">Not enough balance</span>
              </div>
            )}

            {(!inputValue ||
              parseFloat(inputValue.replace(/\s/g, "")) === 0) && (
              <div className="form__warning">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  style={{ marginRight: "4px" }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.00002 12.8332C3.77836 12.8332 1.16669 10.2215 1.16669 6.99984C1.16669 3.77818 3.77836 1.1665 7.00002 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00002 12.8332ZM6.41669 4.08317V7.58317H7.58335V4.08317H6.41669ZM7.00002 9.9165C6.59731 9.9165 6.27085 9.59004 6.27085 9.18734C6.27085 8.78463 6.59731 8.45817 7.00002 8.45817C7.40273 8.45817 7.72919 8.78463 7.72919 9.18734C7.72919 9.59004 7.40273 9.9165 7.00002 9.9165Z"
                    fill="#F04E09"
                  />
                </svg>
                <span className="form-small-red-text">
                  Input value is empty or zero
                </span>
              </div>
            )}

            <span className="form__small-text">
              Balance:{" "}
              {userData ? formatNumber(userData.total_coins) : "Loading..."}
            </span>
          </div>
          <div className="form__column-img">
            <img src="/assets/img/coin.png" alt="" width="44px"></img>
          </div>
        </div>

        <div className="form__first-row">
          <div className="form__circle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="68"
              height="69"
              viewBox="0 0 68 69"
              fill="none"
            >
              <path
                d="M34.2076 17.7925C35.0449 17.7925 35.7237 18.4712 35.7237 19.3085L35.7237 43.3478L41.7416 37.5274C42.3258 36.9624 43.2524 36.9611 43.8382 37.5244C44.4551 38.1177 44.4562 39.1046 43.8405 39.6992L35.2973 47.9492L34.9501 48.2685C34.5304 48.6546 33.8849 48.6546 33.4651 48.2685L33.118 47.9492L24.5748 39.6992C23.9591 39.1046 23.9601 38.1177 24.5771 37.5244C25.1629 36.9611 26.0895 36.9624 26.6737 37.5274L32.6916 43.3478L32.6916 19.3085C32.6916 18.4712 33.3703 17.7925 34.2076 17.7925Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="form__column">
            <span className="form__medium-text">You get</span>
            <span className="form__big-text">
              {inputValue
                ? (parseFloat(inputValue.replace(/\s/g, "")) * rate)
                    .toFixed(2)
                    .replace(/\.?0+$/, "")
                : 0}{" "}
              USDT
            </span>

            <span className="form__small-text">1 COIN = {rate} USDT</span>
          </div>
          <div className="form__column-img">
            <div className="teather-Bg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="31"
                viewBox="0 0 35 31"
                fill="none"
              >
                <g clipPath="url(#clip0_257_3719)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.4087 0.152254L0.0269691 13.8008C0.00268483 13.8515 -0.00501072 13.9089 0.0050079 13.9644C0.0150265 14.02 0.0422337 14.0708 0.0826507 14.1094L17.3182 30.9255C17.3671 30.9733 17.4323 31 17.5002 31C17.568 31 17.6332 30.9733 17.6822 30.9255L34.9177 14.1105C34.9581 14.0718 34.9853 14.021 34.9953 13.9655C35.0053 13.9099 34.9976 13.8526 34.9734 13.8018L28.5916 0.153304C28.571 0.107466 28.5379 0.068636 28.4962 0.0414539C28.4545 0.0142718 28.406 -0.000112442 28.3565 2.03178e-05H6.64586C6.59612 -0.000609785 6.54726 0.013428 6.50518 0.0404402C6.46309 0.0674524 6.42959 0.106285 6.4087 0.152254Z"
                    fill="#50AF95"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.7144 15.2023C19.5906 15.2117 18.9513 15.2506 17.5253 15.2506C16.391 15.2506 15.5857 15.2159 15.3032 15.2023C10.9198 15.006 7.64797 14.2291 7.64797 13.2989C7.64797 12.3687 10.9198 11.5928 15.3032 11.3933V14.4285C15.5898 14.4495 16.4106 14.4989 17.5449 14.4989C18.906 14.4989 19.5875 14.4411 19.7102 14.4296V11.3954C24.0843 11.5938 27.3489 12.3708 27.3489 13.2989C27.3489 14.227 24.0854 15.0039 19.7102 15.2012L19.7144 15.2023ZM19.7144 11.0815V8.36544H25.8187V4.22363H9.1988V8.36544H15.3021V11.0804C10.3413 11.3125 6.61064 12.313 6.61064 13.512C6.61064 14.7109 10.3413 15.7104 15.3021 15.9435V24.6471H19.7133V15.9404C24.6628 15.7083 28.3873 14.7089 28.3873 13.5109C28.3873 12.313 24.6659 11.3135 19.7133 11.0804L19.7144 11.0815Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_257_3719">
                    <rect width="35" height="31" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <span>USDT</span>
          </div>
        </div>

        <div
          className="claim__button-big-yellow"
          onClick={() => {
            if (inputValue && parseFloat(inputValue.replace(/\s/g, "")) !== 0) {
              handleNext();
            } else {
              console.log("Input value is empty or zero");
            }
          }}
        >
          Claim USDT
        </div>
      </div>
    </div>
  );
};

export default ClaimStep1;
